import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import FadeInWhenVisible from '../../../components/fadeInWhenVisible'
import ColumnAlternating from '../../../components/columnAlternating'
import Cta from '../../../components/cta'
import ColumnAlternatingButton from '../../../components/columnAlternatingButton'
import TextOverImage from '../../../components/columnTextOverImage'
import ModalImage from '../../../components/modalImage'
import Card from '../../../components/card'

const Azurestack = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <Cta
          headingLevel={3}
          ctatitle={post.frontmatter.hybridmodel.title}
          ctadescription={post.frontmatter.hybridmodel.description}
          ctalink={post.frontmatter.hybridmodel.link}
          ctalinktext={post.frontmatter.hybridmodel.linktext}
        />

        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <TextOverImage
                title={post.frontmatter.security.title}
                subtitle={post.frontmatter.security.subtitle}
                description={post.frontmatter.security.description}
                image={post.frontmatter.security.image.childImageSharp.gatsbyImageData}
                alt={post.frontmatter.security.alttext}
              />
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => (
                    <Fragment key={index}>
                      <ColumnAlternating
                        textCol="8"
                        imageCol="4"
                        title={sections.title}
                        subtitle={sections.subtitle}
                        subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                        description={sections.description}
                        image={sections.image.childImageSharp.gatsbyImageData}
                        alt={sections.alttext}
                        placement={sections.placement}
                        titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                        link={sections.btnlink}
                        colour={sections.btncolour}
                      />
                      {sections.popupimage && (
                        <ModalImage
                          blankCol="4"
                          buttonCol="8"
                          placement="right"
                          image={sections.popupimage.childImageSharp.gatsbyImageData}
                          alt={sections.popupalttext}
                        />
                      )}
                    </Fragment>
                  ))}
                </div>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        ) : (
          ''
        )}

        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <ColumnAlternatingButton
                textCol="5"
                imageCol="7"
                title={post.frontmatter.govagencies.title}
                subtitle={post.frontmatter.govagencies.subtitle}
                description={post.frontmatter.govagencies.description}
                image={ post.frontmatter.govagencies.image.childImageSharp.gatsbyImageData }
                alt={post.frontmatter.govagencies.alttext}
                placement={post.frontmatter.govagencies.placement}
                titleClass="font-alt font-w-700 letter-spacing-1 mb-4 title-xs-medium title-medium"
                link={post.frontmatter.govagencies.link}
                linktext={post.frontmatter.govagencies.linktext}
              />
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        {post.frontmatter.helpfulresources && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                      <Card
                        key={index}
                        collg="4"
                        colmd="6"
                        height="6.5rem"
                        title={helpfulres.title}
                        subtitle={helpfulres.subtitle}
                        description={helpfulres.description}
                        image={helpfulres.image.childImageSharp.gatsbyImageData}
                        alt={helpfulres.alttext}
                        placement={helpfulres.placement}
                        link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                        titleclass="title-small"
                        descriptionClass="text-card-small"
                      />
                    ))}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}

      <Cta
        ctatitle={post.frontmatter.cta.title}
        ctadescription={post.frontmatter.cta.description}
        ctalink={post.frontmatter.cta.link}
        ctalinktext={post.frontmatter.cta.linktext}
      />
      </main>
    </Layout>
  )
}
export default Azurestack

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "microsoft-azurestack" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug         
        openingpara
        section {
          title
          subtitle
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          placement
          alttext
          btnlink
          btncolour
          popupimage {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          popupalttext
        }
        security {
          title
          subtitle
          description
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
        }
        govagencies {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
        }
        hybridmodel {
          title
          description
          linktext
          link
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            document {
              publicURL
            }
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`