import React, { Component } from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBModal, MDBModalBody, } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/pro-regular-svg-icons'

class ModalPage extends Component {
  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <MDBContainer>
        <MDBRow
          className={`mt-n5 mb-n5 mb-lg-1 about-item align-items-left ${
            this.props.placement === 'right' ? 'flex-row-reverse' : ''
          }`}
        >
          <MDBCol
            lg={this.props.buttonCol ? this.props.buttonCol : '6'}
            className="mt-lg-n5 mt-5 mb-lg-7 mb-7 pl-0 pl-lg-2"
          >
            <MDBBtn onClick={this.toggle} className="nav-link btn btn-mdb-color btn-sm-block" >
              <FontAwesomeIcon icon={faImage} className="mr-2" /> Hardened by
              default
            </MDBBtn>
          </MDBCol>
          <MDBCol
            className="d-none d-sm-block"
            lg={this.props.blankCol ? this.props.blankCol : '6'}
          >
            &nbsp;
          </MDBCol>
        </MDBRow>

        <MDBModal
          isOpen={this.state.modal}
          toggle={this.toggle}
          centered
          size="lg"
        >
          <MDBModalBody>
            <GatsbyImage
              image={this.props.image}
              alt={this.props.alt}
              className="rounded"
            />
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    )
  }
}
export default ModalPage