import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBRow, MDBCol } from 'mdbreact'

class ColumnTextOverImage extends React.Component {
  render() {
    return (
      <MDBRow>
        <MDBCol lg="12">
          <h3 className="font-alt font-w-700 title-xs-medium title-medium">
            {this.props.title}
          </h3>
          {this.props.subtitle != '' && this.props.subtitle != null && (
            <h4
              className={
                this.props.subtitleClass != null
                  ? this.props.subtitleClass
                  : `font-alt font-w-400 letter-spacing-1 mb-4 title-xs-medium title-medium`
              }
            >
              {this.props.subtitle}
            </h4>
          )}
          <div
            className="mt-3 mb-5 font-w-400 text-medium"
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: this.props.description }}
          />
        </MDBCol>
        <MDBCol lg="12" className="text-center">
          <GatsbyImage
            image={this.props.image}
            alt={this.props.alt}
            className="rounded text-center mb-5"
          />
        </MDBCol>
      </MDBRow>
    )
  }
}
export default ColumnTextOverImage